import { RoleFunction } from '@eagle/common';
import { DashboardType } from '@eagle/core-data-types';
import {
  APP_BANNER_HEIGHT,
  APP_BAR_HEIGHT,
  Breadcrumbs,
  DashboardSwitcher,
  DashboardSwitcherLocal,
  ErrorPage,
  MiddleSpinner,
  NotFound,
  PageTitle,
  ReportsIcon,
  SIDEBAR_FULL_WIDTH,
  SIDEBAR_WIDTH,
  SisenseDashboard,
  Undefinable,
  useAuthenticated,
  useBoolFlag,
  useHasAuthorization,
  usePromise,
  useSidebarPinContext,
  useTitle
} from '@eagle/react-common';
import { AppBar, Box, Button, Divider, Link, Stack, Toolbar, Typography, useTheme } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHref, useParams } from 'react-router-dom';

const SYSTEM_ADMINISTRATOR_ROLE = [RoleFunction.SYSTEM_ADMINISTRATOR] as const;

export const ReportsPage: FC = () => {
  const { t } = useTranslation(['track']);
  const { isPinned } = useSidebarPinContext();
  const { restClient } = useAuthenticated();
  const { hasAuthorization } = useHasAuthorization();
  const { dashboardId } = useParams();
  const [dashboardName, setDashboardName] = useState<Undefinable<string>>();
  const v2DashSwitching = useBoolFlag('portals-dashboard-switching-enhancements-temporary-20240918') ?? false;
  const hasSystemAdminPermissions = hasAuthorization(SYSTEM_ADMINISTRATOR_ROLE);
  const renderTitle = useCallback(() => {
    return (
      <DashboardSwitcher dashboardId={dashboardId} type={DashboardType.REPORT} sx={{ flex: 1, justifyItems: 'right', mr: -0.5 }}>
        <Typography noWrap variant="body1" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', mr: 1 , ml: 'auto'}}>
          {dashboardName}
        </Typography>
      </DashboardSwitcher>
    );
  }, [dashboardName, dashboardId]);
  useTitle(renderTitle(), true);
  const theme = useTheme();
  const href = useHref('/reports');
  const manageHref = useHref(dashboardId ? `/reports/manage/${dashboardId}` : '');

  const [dashboard, dashboardError, dashboardState] = usePromise(async () => {
    const filter = { '_id': dashboardId };
    const result = await restClient.my.dashboards.getAllV2({
      filter,
    });
    return result?.items.length ? result?.items[0] : undefined;
  }, [dashboardId, restClient]);

  useEffect(() => {
    if (!dashboard) return;
    setDashboardName(dashboard.display);
  }, [dashboard]);

  if (dashboardState === 'pending') return <MiddleSpinner />;
  if (dashboardError) return <ErrorPage error={dashboardError} />;
  if (!dashboard) return <NotFound />;

  if (v2DashSwitching) {
    return (
      <Box sx={{ width: 1 }}>
        <Stack sx={{ height: 1 }}>
          <Stack
            alignItems="center"
            direction="row"
            sx={{ p: 3, display: { xs: 'none', md: 'flex' } }}
          >
            <Stack spacing={1} flex={1} >
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <PageTitle title={dashboardName} icon={<ReportsIcon />} />
              </Box>
              <Box>
                <DashboardSwitcherLocal type={DashboardType.REPORT} dashboardId={dashboardId}>
                  <Typography variant="body2" sx={{ ml: 5.2 }}>{t('common:component.navigation.switch-to.labels')}</Typography>
                </DashboardSwitcherLocal>
              </Box>
            </Stack>
            {hasSystemAdminPermissions && manageHref
              && <Box>
                <Button href={manageHref} >
                  {t('track:page.dashboards.manage.action')}
                </Button>
              </Box>
            }
          </Stack>
          <Divider />
          <Box sx={{ flexGrow: 1 }}>
            <SisenseDashboard type='reports' dashboardId={dashboardId ?? 'error'} />
          </Box>
        </Stack>
      </Box>
    );
  }

  return (
    <Box sx={{ width: 1, '.showAppBanner & .MuiAppBar-root': { mt: `${APP_BANNER_HEIGHT}px` } }}>
      <AppBar sx={{
        display: { xs: 'none', sm: 'flex' },
        height: APP_BAR_HEIGHT, left: isPinned ? SIDEBAR_FULL_WIDTH : SIDEBAR_WIDTH,
        width: 'unset',
      }}>
        <Toolbar>
          <Breadcrumbs color={theme.palette.common.white}>
            <Link color={theme.palette.common.white} href={href}>
              {t('track:page.reports.title')}
            </Link>
            <DashboardSwitcher dashboardId={dashboardId} type={DashboardType.REPORT}>
              <Typography>{dashboardName}</Typography>
            </DashboardSwitcher>
          </Breadcrumbs>
        </Toolbar>
      </AppBar>
      <Box sx={{ width: 1, height: 1, maxHeight: { xs: 'unset', sm: `calc(100% - ${APP_BAR_HEIGHT}px)` }, overflow: { xs: 'unset', sm: 'auto' }, mt: { xs: 0, sm: `${APP_BAR_HEIGHT}px` } }}>
        <SisenseDashboard type='reports' dashboardId={dashboardId ?? 'error'} />
      </Box>
    </Box>
  );
};
